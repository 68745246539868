@import "./variables.scss";
@import "./typography.scss";

.background-image {
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.onboarding-container {
  display: flex;
  flex-direction: column;;
  justify-content: space-around;

  @media #{$mq-small} {
    flex-direction: row;
    padding: 8rem;
  }

  h1 {
    max-width: 560px;
  }

  .form-container {
    background: $container;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    border-radius: 8px 8px 0 0;
    padding: 32px;

    .logo-horizontal {
      margin: 3rem 0;
    }
  }

  .signup-suggestion {
    color: $secondary;
    background: $elevation;
    border-radius: 0 0 8px 8px;
    padding: 2rem 3rem;
    text-align: center;
  }
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.elevated {
  z-index: 1;
}
