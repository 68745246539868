.confirmation-dialog-screen {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(2, 3, 8, 0.5);
}
.confirmation-dialog-container {
    padding: 1.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    border-radius: 0.5rem;
    background-color: $container;
    width: 28rem;
    .children{
        color: $regular-hovered;
    }
    .button-container {
        justify-content: space-between;
        margin-top: 1rem;
    }
    .button:nth-child(1){
        margin-right: 1.5rem;
    }
    .button {
        width: 100%;
    }
}