.pagination {
  display: flex;
  align-self: center;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;

  ul, li, a {
    color: $secondary;
    text-decoration: none;
    margin: 0 0.5rem;
  }

  li.active a {
    color: $accented;
  }

  ul, li {
    list-style-type : none;
  }
}