.empty-list {
  margin-top: 2rem;

  .MuiSvgIcon-root {
    font-size: 4rem;
  }

  h2, .MuiSvgIcon-root {
    color: $technical;
  }
}
