.clipboard_container{
    position: relative;
    padding-right: 1.3rem;
  }
.clipboard_container:hover{
  color: $accented-hovered;
  cursor: copy;
}
.clipboard_container:active{
  color: $accented-pressed;
}
.clipboard_container .copy_icon {
    @media #{$mq-medium} {
      top: 0.5rem;
      background-size: 100%;
      height: 1.1rem;
      width: 1.1rem;
    }
}
  .copy_icon{
    cursor: copy;
    border: 0;
    position: absolute;
    top: 0.3125rem;
    right: 0;
    background: url(../../assets/icons/copy_24px.svg) no-repeat center;
    height: 0.94rem;
    width: 0.94rem;
  }
  .copy_icon:focus {outline:0;} 