.button {
  border-radius: 4px;
  width: 24rem;
  height: 3rem;
  text-transform: uppercase;
  border: none;
  color: $text-dark;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  font-size: 1.125rem;
  letter-spacing: 0.03125rem;
}

.btn-primary {
  background: $primary;

  &:hover{
    background: $primary-hovered;
  }
  &.btn-primary:active{
    background: $primary-pressed;
  }
  &:focus {
    background: $primary-focused;
  }
  &:disabled {
    background: $primary-disabled;
  }
}

.btn-secondary {
  background: transparent;
  color: $secondary;
    &:hover{
      color: $secondary-hovered;
    }
    &.btn-secondary:active{
      color: $secondary-pressed;
    }
    &:focus {
      color: $secondary-focused;
    }
    &:disabled {
      color: $secondary-disabled;
    }
}