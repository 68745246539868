.table-wrapper {
  flex: 1 1 auto;
  position: inherit;
}

.ReactVirtualized__Table__headerRow {
  background-color: $elevation;
}

.table-custom-header {
  text-transform: capitalize;
  color: $technical;
  font-weight: 500;
  font-size: 0.89rem;
  margin-right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ReactVirtualized__Table__headerTruncatedText {
    white-space: pre-wrap;
    text-align: center;
  }
}

.table-custom-grid {
  background-color: $container;
  border-radius: 0 0 8px 8px;
}

.table-custom-row {
  color: $accented;
  font-size: 0.89rem;
  text-align: center;

  &.odd {
    border-top: 1px solid #363C4D;
  }

  .ReactVirtualized__Table__rowColumn {
    margin-right: 0;
    position: relative;
    padding-right: 1rem;
  }

  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 2rem;
  }

  .ReactVirtualized__Table__rowColumn:first-of-type:hover {
    color: $accented-hovered;
    cursor: copy;
  }
  .ReactVirtualized__Table__rowColumn:first-of-type:active {
    color: $accented-pressed;
  }

  span.Rejected, span.Failed { color: $error; }
  span.Accepted, span.Complete { color: $primary; }
  span.Unknown, span.Sealing { color: $regular; }
}

.ReactVirtualized__Grid:focus {outline:0;}
