@import "../../style/variables.scss";
@import "../../style/typography.scss";

body {
  background: $background;
  margin: 0;
}

.dashboard-container {
  padding: 0 1.33rem;

  .container {
    background: #242B3D;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
    color: $regular;
    border-radius: 8px;
    display: flex;

    .upper {
      border-radius: 8px 8px 0 0;
      padding: 1.78rem;
    }

    .lower {
      background: $elevation;
      padding: 1.78rem;
      border-radius: 0 0 8px 8px;
      flex-wrap: wrap;
    }
  }

  .splitted-row {
    display: flex;
    flex-wrap: wrap;

    .column {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;

      @media #{$mq-medium} {
        width: 50%;
      }
    }
  }

  .column.left .container {
    @media #{$mq-medium} {
      margin-right: 0.67rem;
    }
  }

  .column.right .container  {
    @media #{$mq-medium} {
      margin-left: 0.67rem;
    }
  }

  .tables {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @media #{$mq-medium} {
      flex-direction: row;
    }

    .table-wrapper {
      height: auto;
    }

    .left {
      width: 100%;

      @media #{$mq-medium} {
        width: 60%;
      }
    }

    .right, .right .container {
      display: flex;
      flex: 1;
    }

    .right {
      margin-top: 2.67rem;

      @media #{$mq-medium} {
        margin-top: 0;
      }
    }
  }
}

.vertical-margin {
  margin: 1.34rem 0;
}
.stat .row .MuiSvgIcon-root {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

@import "./NodeNameTitle/node-name-title.scss";
@import "./Instructions/instructions.scss";
