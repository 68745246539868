.general-info {
  color: $accented !important;

  a {
    color: $accented;
  }

  .node-options .MuiSvgIcon-root {
    cursor: pointer;
    user-select: none;
    color: $regular;
    &:hover {
      color: $accented-hovered;
    }
    &:focus {
      color: $regular-focused;
    }
    &:active {
      color: $regular-pressed;
    }
    &.none{
      &.no_hover {
        cursor: default;
        &:hover {
          color: $regular;
        }
        &:focus {
          color: $regular;
        }
        &:active {
          color: $regular;
        }
      }
      &:hover {
        color: $accented-hovered;
      }
      &:focus {
        color: $regular-focused;
      }
      &:active {
        color: $regular-pressed;
      }
    }
    
    &.active{
      color: $primary;
      &.no_hover {
        cursor: default;
        &:hover {
          color: $primary;
        }
        &:focus {
          color: $primary;
        }
        &:active {
          color: $primary;
        }
      }
      &:hover {
        color: $primary-hovered;
      }
      &:focus {
        color: $primary-focused;
      }
      &:active {
        color: $primary-pressed;
      }
    }
  }
  .node-options .MuiSvgIcon-root:nth-child(1){
    padding-right: 0.4rem;
  }
  

  .general-info-stats {
    background: $elevation;
    display: flex;
    justify-content: space-between;

    .stat {
      margin: 0 1.5rem 0 0;
      display: flex;
      flex-direction: column;

      label {
        font-size: 0.78rem;
        line-height: 1.33rem;
      }

      p {
        line-height: 1.33rem;
        font-weight: bold;
        margin: 0;
      }
    }
  }
}