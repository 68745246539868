.node-list-container {
  display: flex;
  flex: 1;

  a {
    color: $accented;
  }

  .nodes-header {
    color: $accented;
    align-items: center;
  }

  .header {
    p {
      margin: 0;
    }
    color: $technical;
    font-size: 0.89rem;
  }

  .node-list {
    background: $container !important;
    color: $accented;

    p {
      font-size: 1.5rem;
      margin-right: 1.125rem;
    }

    p.status {
      color: $primary-faded;
    }
    .node-name {
      cursor: pointer;

      &.selected {
        color: $accented-focused;
        cursor: default;
      }
      &.notSelected:hover {color: $accented-hovered;}
      &.notSelected:active {color: $accented-pressed;}
    }
  }
}