.tooltip-container{
    font-family: Barlow;
    font-size: 1rem;
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5rem;
}
.tooltip-container:last-child{
    margin-top: 0.25rem;
}