input {
  width: 384px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  background: #313849;
  border-radius: 4px;
  color: $accented;
  padding: 16px;
  box-sizing: border-box;
  border: none;

  &:focus {
    background: $input-active;
  }
}

input::placeholder {
  color: $hint;
  font-size: 18px;
}
.input-wrapper{
  display: flex;
  flex-direction: column;
  height: 5rem;
}
.input-container {
  display: flex;
  width: 100%;

  .MuiSvgIcon-root {
    position: absolute;
    padding: 12px;
    color: $hint;
  }

  &.has-icon input {
    padding-left: 46px;
  }

}
.error-message {
  color: $error;
  font-size: 1rem;
}
