$background: #1D2333;
$container: #242B3D;
$elevation: #282F41;
$hover: #2D3345;

$separator: #363C4D;
//$input-inactive: #31384;
$input-active: #3A4050;
$hint: #4C5670;

$primary: #EECA1C;
$primary-hovered: #FFD81E;
$primary-focused: #FFDC33;
$primary-pressed: #FFE35C;
$primary-disabled: #E0D9B4;
$primary-faded: #756B30;

$secondary: #6B7799;
$secondary-hovered: #8493BD;
$secondary-focused: #939EBD;
$secondary-pressed: #A3ADC7;
$secondary-disabled: #3F424D;

$accented: #E1E6F5;
$accented-hovered: #C0C5D1;
$accented-focused: #ADB1BD;
$accented-pressed: #A4A8B3;
$accented-disabled: #46484D;

$regular: #A8B2CC;
$regular-hovered: #A8B2CC;
$regular-focused: #A0A9C2;
$regular-pressed: #939CB3;
$regular-disabled: #3F434D;

$error: #F47979;
$text-dark: #2E3742;
$technical: #545F7C;
