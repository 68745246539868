label {
  text-transform: uppercase;
  color: $secondary;
  letter-spacing: 0.6px;
}

.logo-horizontal {
  background: url(../assets/images/logo-horizontal.svg) no-repeat;
  width: 310px;
  height: 50px;
}

.row {
  display: flex;
}

.stretch {
  display: flex;
  flex: 1;
}

.wrapped {
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.row-spaced {
  display: flex;
  justify-content: space-between;
}

.self-centered {
  display: flex;
  align-self: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.break-row {
  flex-basis: 100%;
  height: 0;
}

.no-line-height {
  line-height: 0;
}

.hidden {
  display: none !important;
}

.alert{
  color: $primary;
}
.capitalize {
  text-transform: capitalize;
}

.clipboard_container {
  cursor: pointer !important;
}
