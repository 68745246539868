.node-name-title {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root{
        padding-top: 0.75rem;
    }
    &.active {
        cursor: pointer;
    }
    &.active:hover {color: $accented-hovered;}
    &.active:active {color: $accented-pressed;}
    
    &.inactive {
        cursor: default;
    }
}

.node-name-description {
    font-size: 1rem;
    color: $regular;
}

