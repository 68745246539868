.chart-header {
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 1rem;

  .row {
    align-items: center;
    color: $accented;
    font-size: 0.89rem;

    .MuiSvgIcon-root {
      color: $primary;
    }

    .MuiSvgIcon-root, img {
      margin-right: 0.4rem;
    }
  }

  .time {
    padding-right: 0;
    padding-top: 1rem;
    align-items: flex-start;

    span {
      color: $secondary;
      cursor: pointer;
      padding-right: 1rem;

      &:last-child {
        padding-right: 0;
      }

      &.selected {
        color: $accented;
        font-weight: bold;
      }
    }
  }

  .tooltip-values {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }

  .row.icon-padding {
    padding-right: 2rem;
  }
}