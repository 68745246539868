.dropdown {
    background-color: $hover;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 3rem;
    width: 11.5rem;
    
    .item{
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      z-index: 3;
      display: flex;
      align-items: center;
      height: 3.2rem;
      user-select: none;
      cursor: pointer;
      .MuiSvgIcon-root {
        padding: 0 0.625rem
      }
    }
    .item:hover {
      background-color: #353B4C;
    }
  }

  .dropdown-screen{
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
  }