.top-bar {
  display: flex;
  justify-content: space-between;
  color: $regular;
  border-radius: 0 0 8px 8px !important;
  padding: 0 !important;
  .bar-element {
    display: flex;
    margin: 1rem 1.94rem;
    max-height: 1.33rem;
    align-items: center;
    position: relative;
  }
  .bar-element:nth-child(2) {
    cursor: pointer;
  }
  .dropdown{
    bottom: -3.75rem;
    right: 0;
  }
}